import type { StorefrontConfig } from "@redotech/commerce-cloud-sdk/rpc/schema/get-storefront-config";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { memo, useEffect, useRef, useState } from "react";
import * as cartCard from "./card-card.module.css";
import { addItem, log, removeItem, updateQuantity, useCart } from "./util";

const STATE_KEY = "redo_enabled";

export const CartCard = memo(function CartCard({
  config,
}: {
  config: StorefrontConfig;
}) {
  const cart = useCart();
  const redoInCart = cart?.items.some((item) => item.brand === "re:do");
  const cartEligible = cart?.items
    .filter((item) => item.brand !== "re:do")
    .some(
      (item) =>
        !item.onlineCatigIds?.some((id) =>
          config.coverageExcludeCategoryIds?.includes(id),
        ),
    );
  const [redoEnabled, setRedoEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    if (cart && redoEnabled === null) {
      setRedoEnabled(!!redoInCart);
    }
  }, [cartEligible, cart, redoInCart, redoEnabled]);

  const ensurePromiseRef = useRef<Promise<void>>();
  const ensureRedoLoad = useLoad(
    async (signal) => {
      if (!cart || redoEnabled === null) {
        return;
      }
      while (ensurePromiseRef.current) {
        try {
          await ensurePromiseRef.current;
        } catch (error) {
          // NOOP
        }
      }
      ensurePromiseRef.current = (async () => {
        const state = getLocalStorageWithExpiry(STATE_KEY);
        const shouldHaveRedo = cartEligible && (redoEnabled || state === null);
        log("Ensuring redo", {
          redoEnabled,
          cartEligible,
          shouldHaveRedo,
          state,
        });
        let redoFound = false;
        for (const item of cart?.items ?? []) {
          if (item.brand === "re:do") {
            redoFound = true;
            if (!shouldHaveRedo) {
              log("Removing redo", { item });
              await removeItem(item, signal);
              if (redoEnabled && !cartEligible) {
                localStorage.removeItem(STATE_KEY);
              } else {
                setLocalStorageWithExpiry(STATE_KEY, "false");
              }
            } else if (item.quantity !== 1) {
              log("Setting redo quantity to 1", { item });
              await updateQuantity(item, 1, signal);
            }
          }
        }
        if (shouldHaveRedo && !redoFound) {
          if (state === null) {
            log("adding redo");
            await addItem(config.productSkus.returns, 1, signal);
            setLocalStorageWithExpiry(STATE_KEY, "true");
            setRedoEnabled(true);
          } else if (state === "true") {
            log("redo removed");
            setLocalStorageWithExpiry(STATE_KEY, "false");
          }
        }
        (window as any).reloadCart?.();
      })();

      try {
        await ensurePromiseRef.current;
      } finally {
        ensurePromiseRef.current = undefined;
      }
    },
    [cart, redoEnabled, cartEligible],
  );

  const toggleState = useHandler(() => {
    if (ensureRedoLoad.pending) {
      return;
    }
    setRedoEnabled(!redoEnabled);
    if (!redoEnabled) {
      localStorage.removeItem(STATE_KEY);
    }
  });
  if (!cartEligible) {
    return null;
  }
  const formatter = CURRENCY_FORMAT(config.coveragePrice.currency);
  return (
    <div className={cartCard.container}>
      {config.logo ? (
        <div className={cartCard.logoContainer}>
          <img className={cartCard.logo} src={config.logo} />
        </div>
      ) : null}
      <div className={cartCard.main}>
        <div className={cartCard.title}>
          {config.toggleTextOptions.returnToggle.title ||
            "Free and Easy Returns"}
        </div>
        <div className={cartCard.subtitle}>
          {redoEnabled === true
            ? config.toggleTextOptions.returnToggle.positiveSubtext ||
              "Free returns on your order"
            : redoEnabled === false
              ? config.toggleTextOptions.returnToggle.negativeSubtext ||
                "Free returns on your order"
              : null}
        </div>
        <div className={cartCard.bottom}>
          <div className={cartCard.feature}>
            {formatter.format(+config.coveragePrice.amount)}
          </div>
          <button
            className={cartCard.button}
            disabled={ensureRedoLoad.pending}
            onClick={toggleState}
          >
            {redoEnabled ? "Remove" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
});
