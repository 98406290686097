import { useWindowEventListener } from "@redotech/react-util/event";
import { useTriggerLoad } from "@redotech/react-util/load";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { memo } from "react";
import * as exchangeSelect from "./exchange-select.module.css";
import { getCart } from "./util";

export const ExchangeSelectBanner = memo(function ExchangeSelectBanner() {
  const [doneLoad, done] = useTriggerLoad(async (signal) => {
    const cart = await getCart(signal);
    opener?.postMessage(
      {
        type: "redo.exchange-select.complete",
        items: cart.items
          .filter(
            (item) => true, // TODO: filter out Redo
          )
          .map((item) => ({ id: item.id, quantity: item.quantity })),
      },
      "*",
    );
    console.log(cart);
  });

  useWindowEventListener(
    window,
    "click",
    (event) => {
      if (event.target instanceof HTMLElement) {
        if (event.target.closest(".proceed-to-cart-applepay")) {
          event.preventDefault();
          event.stopPropagation();
          done();
        }
      }
    },
    true,
  );

  const bannerClassName = `${exchangeSelect.bannerBase} ${exchangeSelect.banner}`;

  return (
    <header className={bannerClassName}>
      <div className={exchangeSelect.title}>
        Select your new items and add them to the cart.
      </div>
      <Button
        onClick={() => done()}
        pending={doneLoad.pending}
        size={ButtonSize.SMALL}
        theme={ButtonTheme.OUTLINED}
      >
        Done
      </Button>
    </header>
  );
});
