// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/cjs.js??css!../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_postcss-loader_8.1.1-dc3fc578/files/dist/cjs.js??postcss!../../../external/npm_modern-css-reset_1.4.0/files/dist/reset.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Sensible defaults\n */\n\n:disabled {\n  pointer-events: none;\n}\n\n* {\n  background: unset;\n  border-spacing: unset;\n  border: unset;\n  box-sizing: border-box;\n  color: unset;\n  font-style: unset;\n  font-weight: unset;\n  list-style: none;\n  margin: unset;\n  outline: unset;\n  padding: unset;\n  text-align: unset;\n  text-decoration: unset;\n}\n\na,\nbutton {\n  cursor: pointer;\n}\n\nb,\nstrong {\n  font-weight: bold;\n}\n\nem,\ni {\n  font-style: italic;\n}\n\nu {\n  text-decoration: underline;\n}\n\ns {\n  text-decoration: line-through;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
