import { z } from "zod";

const toggleTextSchema = z.object({
  title: z.string().optional(),
  positiveSubtext: z.string().optional(),
  negativeSubtext: z.string().optional(),
});

const storefrontConfigSchema = z.object({
  logo: z.string().nullish(),
  coveragePrice: z.object({ amount: z.string(), currency: z.string() }),
  productSkus: z.object({ returns: z.string() }),
  toggleSelector: z.string(),
  toggleTextOptions: z.object({ returnToggle: toggleTextSchema }),
  togglePlacement: z.enum(["before", "after", "prepend", "append"]),
  countries: z.array(z.string()),
  hideProduct: z.object({
    enabled: z.boolean(),
    selector: z.string().nullish(),
  }),
  coverageExcludeCategoryIds: z.array(z.string()),
});

export type StorefrontConfig = z.infer<typeof storefrontConfigSchema>;

export const getStorefrontConfig = {
  input: z.object({ widgetId: z.string() }),
  output: z.union([
    storefrontConfigSchema.extend({ enabled: z.literal(true) }),
    z.object({ enabled: z.literal(false) }),
  ]),
};
