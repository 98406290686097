import { lazy } from "@redotech/util/cache";
import { Observable, Subject } from "rxjs";

export interface HttpInfo {
  request: { url: string; body?: string | null | undefined };
}

/**
 * Observable for HTTP responses.
 */
export const httpResponseEvents: Observable<HttpInfo> = new Observable(
  (subscriber) => {
    const subscription = httpResponseEventsInternal().subscribe(subscriber);
    return () => subscription.unsubscribe();
  },
);

/**
 * Lazy observable, shim once.
 * Note: It's not safe to unset the shims, because other shims may have been
 * added on top of this.
 */
const httpResponseEventsInternal = lazy(() => {
  const events = new Subject<HttpInfo>();
  window.fetch = wrapFetch(events, window.fetch);
  XMLHttpRequest.prototype.send = wrapXhrSend(
    events,
    XMLHttpRequest.prototype.send,
  );
  return events;
});

function wrapFetch(
  responseEvent: Subject<HttpInfo>,
  fetch: typeof window.fetch,
): typeof window.fetch {
  return async function (
    this: Window & typeof globalThis,
    ...args: Parameters<typeof window.fetch>
  ) {
    try {
      return await fetch.apply(this, args);
    } finally {
      const body =
        typeof args[1] === "string" ? args[1] : args[1]?.body?.toString();
      const url =
        typeof args[0] === "string"
          ? args[0]
          : args[0] instanceof URL
            ? args[0].toString()
            : args[0].url;
      responseEvent.next({ request: { url, body } });
    }
  };
}

function wrapXhrSend(
  responseEvent: Subject<HttpInfo>,
  send: typeof XMLHttpRequest.prototype.send,
): typeof XMLHttpRequest.prototype.send {
  return async function (
    this: XMLHttpRequest,
    ...args: Parameters<typeof XMLHttpRequest.prototype.send>
  ) {
    this.addEventListener("readystatechange", function (event) {
      // HEADERS_RECEIVED is usually 2, but if a merchant overrides the whole XMLHttpRequest object, it can be undefined >:(
      const headersReceived = XMLHttpRequest.HEADERS_RECEIVED ?? 2;

      if (this.readyState === headersReceived) {
        responseEvent.next({ request: { url: this.responseURL } });
      }
    });
    return send.apply(this, args);
  };
}
